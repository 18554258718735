<template>
    <div class="battery">
      <div class="batteryHeader">
        <img src="@/assets/tcjy-header.png" alt="">
      </div>
      <h3 class="batteryTitle">服务详情登记</h3>
      <van-form @submit="onSubmit">
        <van-cell-group>
          <van-field name="rate" label="师傅服务评分">
            <template #input>
              <van-rate v-model="form.rate_value" color="#07c160"/>
            </template>
          </van-field>
          <van-field v-model="form.rate_desc" rows="4" autosize label="对服务反馈" type="textarea" maxlength="200" placeholder="如果您有什么不满意或者建议，可以在这里给我们反馈" show-word-limit/>
          <van-field name="radio" label="师傅是否销售给您汽车电池">
            <template #input>
              <van-radio-group v-model="form.is_battery" direction="horizontal" checked-color="#07c160">
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="radio" label="师傅承诺给您电池售后服务" v-if="form.is_battery == 1">
            <template #input><van-radio-group v-model="form.is_aftersale" direction="horizontal" checked-color="#07c160">
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field  v-if="form.is_battery == 1" v-model="form.battery_date" is-link readonly name="picker" label="电池质保日期" placeholder="点击选择电池质保日期" @click="showPicker = true"/>
          <van-popup  v-if="form.is_battery == 1" v-model:show="showPicker" position="bottom" title="标题"><van-picker show-toolbar title="电池质保日期" :columns="columns" @confirm="onConfirm" @cancel="showPicker = false"/></van-popup>
          <van-field  v-if="form.is_battery == 1" v-model="form.battery_cost" label="电池价格(元)"  placeholder="请输入电池价格" />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button :loading="submitLoading" round block type="primary" native-type="submit">提交</van-button>
        </div>
        <div class="batteryTips">
          <p>如有售后，投诉等问题可拨打:</p>
          <span>4000-929-555</span>
          <span>19928790009</span>
        </div>
      </van-form>
    </div>
</template>

<script>
import util from '@/utils';
import Data from '@/utils/data';
import { isWeiXin, isSafari } from '@/utils/index';
import {orderEvaluate} from "@/apis/rescue";



export default {
    data(){
        return {
            form: {
              order_id:"",
              rate_value: 0,
              rate_desc:"",
              is_battery: '',
              is_aftersale: '',
              battery_date: "",
              battery_cost: "",
            },
            showPicker:false,
            submitLoading:false,
            columns:[
              { text: '6个月', value: '6' },
              { text: '12个月', value: '12' },
              { text: '18个月', value: '18' },
              { text: '24个月', value: '24' },
              { text: '36个月', value: '36' },
              { text: '其他', value: '-1' },
            ]
        }
    },
    created(){
        this.form.order_id = this.$route.query.id;
    },
    mounted(){

    },
    methods: {
        onSubmit(){
            let that = this;
            let data = {
                ...this.form
            }
            if(data.rate_value <=0){
              this.$toast('请给师傅服务评分！');
              return
            }
            if(data.is_battery === '' ){
              this.$toast('请选择师傅是否给您销售汽车电池！');
              return
            }
            if(data.is_battery == 1 && data.is_aftersale === '' ){
              this.$toast('请选择师傅承诺给您电池售后服务！');
              return
            }
            this.submitLoading = true;
            orderEvaluate(data).then((res)=>{
                that.$toast(res.data.info);
                that.submitLoading = false;
            })
        },
        onConfirm(row){
          this.form.battery_date = row.text;
          this.showPicker = false;
        }
    }
}
</script>

<style lang='scss' scoped>
    .battery{
      .batteryHeader{

      }
      .batteryHeader img{
        width: 100%;
      }
      .batteryTitle{
        text-align: center;
        font-size: 0.55rem;
        font-weight: bold;
        color: #1c1f5a;
        margin-top: -0.4rem;
        margin-bottom: 0.2rem;
        letter-spacing: 0.10667rem;
        padding: 10px 0;
      }
      .batteryTips{
        font-size: 0.35rem;
        text-align: center;
      }
      .batteryTips span{
        color: red;
        margin: 20px;
        font-size: 0.5rem;
        line-height: 1rem;
        font-weight: bold;
      }
    }
</style>
